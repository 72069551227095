export const colors = {
  primary: "#ffd54d",
  black: "#333333",
  lightTan: "#f9eac0",
  butterscotch: "#f5ad47",
  lightGrey: "#f5f5f5",
  lightMustard: "#fac46a",
  pale: "#fff7d0",
  brownGrey: "#808080",
  whiteThree: "#eaeaea",
  white4: "#fafafa",
  lightSage: "#f1fbf6",
  greenishTeal: "#3cc480",
  pastelRed: "#e35656",
  veryLightPink: "#fef7f7",
  warmGrey: "#9d9d9d",
  pale60: "rgba(255, 247, 208, 0.6)",
  mediumGreen: "#3fb94d",

  // not defined in style guide, but use in some disabled text
  disabled: "#b4b4b4",
}
