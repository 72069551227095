import PropTypes from "prop-types"
import styled from "@emotion/styled"

import {SpacingTypes, spacings} from "../../styles/theme"

/**
 * @typedef {object} SpacingProps
 * @property {keyof spacings =} spacing
 * @property {number=} height
 * @property {number=} width
 */

/**
 * @type {React.FunctionComponent<SpacingProps>}
 */
export const Spacing = styled.div`
  width: ${props => spacings[props.spacing] || props.width}px;
  height: ${props => spacings[props.spacing] || props.height}px;
`

Spacing.propTypes = {
  spacing: PropTypes.oneOf(Object.values(SpacingTypes)),
  width: PropTypes.number,
  height: PropTypes.number,
}
Spacing.defaultProps = {
  spacing: SpacingTypes.none,
  width: 0,
  height: 0,
}
