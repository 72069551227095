/** @enum { 'none'| 'xs' | 's' | 'm' | 'l' | 'xl' |'xxl' | 'xxl-2'| 'xxl-3' | 'xxl-4' } */
export const SpacingTypes = {
  none: "none",
  xs: "xs",
  s: "s",
  m: "m",
  l: "l",
  xl: "xl",
  xxl: "xxl",
  "xxl-2": "xxl-2",
  "xxl-3": "xxl-3",
  "xxl-4": "xxl-4",
}

export const spacings = {
  none: 0,
  xs: 8,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  xxl: 28,
  "xxl-2": 36,
  "xxl-3": 40,
  "xxl-4": 50,
}
